










































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfButton, SfHeading, SfLoader } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  layout: 'full-width',
  name: 'ApprovalConfirmation',
  components: {
    SfHeading,
    SfButton,
    SfLoader,
    SvgImage
  },
  setup() {
    const { user, load } = useUser();
    const firstnameDirigeant = ref('');
    const lastnameDirigeant = ref('');

    if (user.value === null) {
      load();
    } else {
      firstnameDirigeant.value = user.value.dirigeant?.firstname;
      lastnameDirigeant.value = user.value.dirigeant?.lastname;
    }

    return {
      firstnameDirigeant,
      lastnameDirigeant
    }
  },
});
